export enum Role {
  Player = 'player',
  TeamManager = 'team_manager',
  TeamAdmin = 'team_admin',
  CompanyManager = 'company_manager',
  Champion = 'champion',
  CSM = 'csm',

  /* This role primarily corresponds to staff tools users. However, some Arcade accounts have this as their role */
  Admin = 'admin',
}
