import axios, { AxiosInstance } from 'axios'
import { createStandaloneToast } from '@chakra-ui/react'
import { get } from 'lodash'
import { camelizeKeys } from 'humps'
import { getLocalAuth } from '../providers'
import { AUTH_STORAGE_KEY } from 'ui/types'
import { checkClientVersion, storeAndCompareFeatureHash } from '../utils'
import { ApiStatusCodes, ApiMessages } from '../types/ApiResponse'
/*
The API endpoint needs to be pulled from localstorage after login; 
Next doesn't allow accessing window during prerender; so this file
creates the Axios object on the fly when a method is called.

Using a proxy this way is a hack! If you are building from scratch, 
don't do this! But given we already use `arcadeApiClient` all over 
the codebase, this is a sneaky trick to prevent Next from complaining
about the non-existence of window.localStorage serverside.
*/
var clientId: string = ''
var clientMemo: AxiosInstance | null = null
export function createArcadeApiClient() {
  const auth = JSON.parse(
    window?.localStorage.getItem(AUTH_STORAGE_KEY) || '{}',
  )
  const currentClientId = auth.email + auth.token + auth.endpoint
  if (currentClientId === clientId && clientMemo) return clientMemo
  var client = axios.create({
    baseURL: auth.endpoint || process.env.ARCADE_API_ENDPOINT,
  })
  client.defaults.headers.common['X-User-Token'] = auth.token
  client.defaults.headers.common['X-User-Email'] = auth.email
  client.defaults.headers.common['Accept'] = 'application/json'

  client.interceptors.response.use(checkClientVersion)
  client.interceptors.response.use(
    storeAndCompareFeatureHash,
    checkIfUserRequiresVerification,
  )
  client.interceptors.response.use(camelizeResponse, maybeLogoutUser)
  clientId = currentClientId
  clientMemo = client
  return client
}

const handler = {
  // An Axios instance can act like an object or a function, so
  // use both `get` and `apply` handlers
  get(_target: object, prop: string, _receiver: any) {
    return createArcadeApiClient()[prop]
  },
  apply(_target: object, _thisArg: any, argumentList: [any]) {
    return createArcadeApiClient()(...argumentList)
  },
}

export const arcadeApiClient = new Proxy(() => {}, handler) as AxiosInstance

export default arcadeApiClient

function camelizeResponse(response) {
  /*
    Calling camelizeKeys on a blob prematurely converts the data to a string. This causes the axios handler for file downloading to not function as expected.
  */
  if (response.data instanceof Blob) {
    return response
  }

  response.data = camelizeKeys(response.data)
  return response
}

function maybeLogoutUser(error) {
  const statusCode = get(error, 'response.status')
  const message = get(error, 'response.data.message')

  const shouldLogout = (() => {
    switch (true) {
      case statusCode === ApiStatusCodes.Unauthorized:
        return true
      // We send 403 for other reasons, so we need to check the message
      case statusCode === ApiStatusCodes.Forbidden &&
        message === ApiMessages.Locked:
        return true
      default:
        return false
    }
  })()

  if (shouldLogout) logout()

  return Promise.reject(error)
}

const checkIfUserRequiresVerification = error => {
  const statusCode = get(error, 'response.status')
  const message = get(error, 'response.data.message')

  if (
    statusCode === ApiStatusCodes.Forbidden &&
    message === ApiMessages.RequiresVerification
  ) {
    const { toast } = createStandaloneToast()
    toast({
      title: 'Verification Required',
      description: 'Please verify your email address to continue.',
      status: 'info',
    })
  }

  return Promise.reject(error)
}

function logout() {
  getLocalAuth().clear()
  window.location.href = '/login'
}
