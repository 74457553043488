export default {
  sizes: {
    sm: {
      popper: {
        width: 'fit-content',
        maxWidth: 'fit-content',
      },
    },
  },
}
