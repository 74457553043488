const padding = { base: 8, sm: 10 }

export default {
  baseStyle: {
    overlay: {
      background: 'rgba(5, 37, 91, 0.35)',
    },
    dialog: {
      borderRadius: 12,
    },
    header: {
      color: 'text.primary',
      fontFamily: 'codenext',
      fontSize: { base: 'xl', sm: '2xl' },
      lineHeight: 1.3,
      marginBottom: 4,
      paddingTop: padding,
      paddingBottom: 0,
      paddingX: padding,
    },
    body: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingX: padding,
    },
    footer: {
      paddingTop: 6,
      paddingBottom: padding,
      paddingX: padding,
    },
  },
}
