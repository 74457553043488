import { Role } from 'types/Role'

export const ROLE_NAMES_PERMISSION_HIERARCHY = {
  [Role.Player]: 0,
  [Role.TeamManager]: 1,
  [Role.TeamAdmin]: 2,
  [Role.CompanyManager]: 3,
  [Role.Champion]: 4,
  [Role.CSM]: 5,
  [Role.Admin]: 6,
}

export const userRoleIsAtLeast = ({
  requiredRole,
  userRole,
}: {
  requiredRole: string
  userRole: string
}): boolean => {
  const VALID_ROLES = Object.keys(ROLE_NAMES_PERMISSION_HIERARCHY)

  /* This is to protect against cases where an undefined value gets passed in for role. The hunch is that this happens when we attempt to perform a role check before roles have loaded */
  if (!VALID_ROLES.includes(userRole)) {
    return false
  }

  return (
    ROLE_NAMES_PERMISSION_HIERARCHY[requiredRole] <=
    ROLE_NAMES_PERMISSION_HIERARCHY[userRole]
  )
}
