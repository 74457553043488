import { useContext } from 'react'
import useSWR from 'swr'
import moment from 'moment'
import { arcadeApiClient } from 'apiClients'
import {
  AnalyzeDashboardContext,
  FilterOptions,
} from 'providers/dashboards/AnalyzeDashboardProvider'
import { PeriodType } from 'components/AnalyzePeriodHeader'
import { AnalyzeType } from 'components/AnalyzeTypeHeader'
import { getDateFromDateTime } from 'ui/helpers'

const customPeriodFetcher = ([url, graphFilterOptions, analyzeType]) =>
  arcadeApiClient
    .get(url, {
      params: requestParams(graphFilterOptions, analyzeType),
    })
    .then(res => res.data)

export const requestParams = (
  graphFilterOptions: FilterOptions,
  analyzeType: AnalyzeType,
) => {
  switch (analyzeType) {
    case AnalyzeType.Game:
      return {
        ...customPeriodParams(graphFilterOptions),
        team_ids: graphFilterOptions.teams?.map(team => team.id),
      }
    case AnalyzeType.Metric:
      return {
        ...customPeriodParams(graphFilterOptions),
        ...metricAnalysisParams(graphFilterOptions),
      }
  }
}

const customPeriodParams = (graphFilterOptions: FilterOptions) => ({
  period_type: graphFilterOptions.periodType?.value,
  period_count: graphFilterOptions.periodCount?.value,
  custom_periods: formatCustomPeriods(
    graphFilterOptions.customComparisonPeriods,
    graphFilterOptions.periodCount?.value as number,
  ),
})

const metricAnalysisParams = (graphFilterOptions: FilterOptions) => ({
  metric_id: graphFilterOptions.metric?.value,
  team_ids: graphFilterOptions.teams?.map(team => team.id),
  start_date: getDateFromDateTime(
    graphFilterOptions.metricReportingRange?.[0] as Date,
  ),
  end_date: getDateFromDateTime(
    graphFilterOptions.metricReportingRange?.[1] as Date,
  ),
})

const formatCustomPeriods = (
  dateRanges: { [key: number]: Date[] },
  periodCount: number,
) => {
  const validRangeIndexes = Object.keys(dateRanges).filter(
    key => parseInt(key) <= periodCount && isCustomPeriodValid(dateRanges[key]),
  )

  const customPeriods = validRangeIndexes.map(rangeIndex => {
    const [periodStart, periodEnd] = dateRanges[rangeIndex]

    const localStart = moment.utc(periodStart).local().format()
    const localEnd = moment.utc(periodEnd).local().format()

    return `${localStart},${localEnd}`
  })

  return customPeriods
}

export const areFilterOptionsValid = (
  filterOptions: FilterOptions,
  analyzeType: AnalyzeType,
) => {
  switch (analyzeType) {
    case AnalyzeType.Game:
      return arePeriodFilterOptionsValid(filterOptions)
    case AnalyzeType.Metric:
      return (
        arePeriodFilterOptionsValid(filterOptions) &&
        areMetricFilterOptionsValid(filterOptions)
      )
  }
}

const areMetricFilterOptionsValid = (filterOptions: FilterOptions) =>
  filterOptions.metric &&
  filterOptions.teams &&
  filterOptions.metricReportingRange?.length

const arePeriodFilterOptionsValid = filterOptions => {
  if (!filterOptions.periodCount || !filterOptions.periodType) {
    return false
  }

  if (filterOptions.periodType?.value == PeriodType.Custom) {
    return (
      formatCustomPeriods(
        filterOptions.customComparisonPeriods,
        filterOptions.periodCount.value,
      ).length == filterOptions.periodCount?.value
    )
  }

  return true
}

const isCustomPeriodValid = (period: Date[]) =>
  period.filter(date => !!date).length == 2

export const useInsights = (requestUrl: string) => {
  const { graphFilterOptions, analyzeType } = useContext(
    AnalyzeDashboardContext,
  )

  const urlObject = [requestUrl, graphFilterOptions, analyzeType]

  return useSWR(
    areFilterOptionsValid(graphFilterOptions, analyzeType) ? urlObject : null,
    customPeriodFetcher,
  )
}
